* {
	font-family: 'Comfortaa', cursive;
	box-sizing: border-box;
}

a,
a:hover,
a:focus,
a:active,
a *,
a *:hover,
a *:focus,
a *:active {
	text-decoration: none;
}

.MuiBackdrop-root.MuiModal-backdrop {
	background: #2b367454;
}

body {
	overflow-x: hidden;
}
