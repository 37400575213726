.react_pdf_document {
	background-color: transparent;
	display: flex;
	gap: 20px;
}

.react_pdf_pages {
	display: flex;
	justify-content: center;
	height: 100%;
	background-color: #fff;
}
