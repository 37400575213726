:root {
	--royalBlue: '#4779E5';
	--astronaut: '#2B3674';
	--baliHai: '#7E89AC';
	--rockBlue: '#A3AED0';
	--periwinkle: '#D0DEFF';
	--zircon: '#ECF2FF';
	--catskillWhite: '#F4F7FA';
	--white: '#FFFFFF';

	--ebonyClay: '#222438';
	--charade: '#2F3343';
	--brightGray: '#3D4255';
	--manatee: '#989BA7';

	--persianPink: '#F868BD';
	--atlantis: '#86D24A';
	--scooter: '#34BEC6';
	--portage: '#908FFA';

	--violetRed: '#FA4D81';
	--fuelYellow: '#EEAB2A';
	--jungleGreen: '#24B16A';

	--narvik: '#EAF8F1';
	--offYellow: '#FEF7EA';
	--lavenderBlush: '#FFEEF3';
}
