.react_pdf_thumbnail {
	background-color: #f4f7fa;
	border-radius: 8px;
	padding: 16px 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid #f4f7fa;
}

.react_pdf_thumbnail > div {
	height: 45px;
	width: 45px;
	overflow: hidden;
	border-radius: 4px;
	box-shadow: 5.786px 5.786px 23.145px 0px #babecc, -9.258px -9.258px 11.573px 0px #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.react_pdf_thumbnail_active {
	border: 2px solid #4779e5;
}
